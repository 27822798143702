@import "@assets/styles/variables";

.review_container_title {
  font-weight: 400;
  font-size: 0.75rem;
  color: $black_medium_emphasis;
}

.review_container {
  display: flex;
  flex-direction: column;
  border: 1px solid $outline;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
}

.section_title {
  font-weight: 600;
  font-size: 0.75rem;
}

.section_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  > div {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
}

.section_items {
  p {
    margin: 0;
  }

  .label {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: $black_medium_emphasis;
  }

  .value {
    font-size: 1rem;
    font-weight: 400;
  }

  &.changed {
    border-radius: 10px;
    background-color: $primary_50;
    padding: 0.7rem 1rem;

    .label {
      font-weight: 500;
    }
  }
}
