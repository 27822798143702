@import "src/assets/styles/variables";

.container {
  margin-top: -1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-width: 345px;
}

.forgot_button_wrapper {
  margin-bottom: 20px;
  display: flex;
  margin-top: 0.5rem;
}

.centered_text {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
