@import "@assets/styles/variables";

.price_container {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }

  p:first-of-type {
    font-weight: 400;
    font-size: 24px;
    margin-right: 0.4rem;
    color: $black_low_emphasis;
    text-decoration: line-through;
    text-decoration-color: $black_low_emphasis;
  }
}
