@import "@assets/styles/variables";
@import "@assets/styles/mixins";

.drop_down {
  @include transition();
  margin-bottom: 1rem;
  cursor: pointer;
  z-index: 2;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid $outline;
  padding: 0.2rem 0.9rem;

  svg {
    @include transition();
    transform: rotate(0deg);
  }

  p {
    margin: 0 0.2rem 0 0;
  }

  &.open {
    border: 1px solid $primary_200;

    svg {
      transform: rotate(-180deg);
    }
  }

  &:hover {
    border: 1px solid $black_medium_emphasis;
  }
}

.filter_column {
  display: flex;
  flex-direction: column;
}

.filter_title {
  margin-left: 1.1rem;
  font-size: 1rem;
  font-weight: 500;
}

.filter_row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;

  p {
    margin: 0 0 0 0.5rem;
  }

  :global .MuiCheckbox-root {
    padding: unset;

    &:hover {
      background-color: transparent !important;
    }
  }
}
