@import "@assets/styles/variables";

.container {
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid $outline;
  margin-bottom: 1rem;

  p {
    font-size: 0.9rem;
    color: $black_medium_emphasis;
    margin: 0 0 0.2rem;
  }

  p:nth-child(1) {
    color: $primary_700;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.7px;
    margin-bottom: 0.8rem;
  }

  p:nth-child(2) {
    color: $primary_700;
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
}

.loading {
  margin-inline: 1rem;
  height: 200px !important;
}
