@import "src/assets/styles";

.service_row_container {
  .row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .item {
    margin-right: 2.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem 0;
  }

  .label {
    color: $black_medium_emphasis;
    font-size: 0.75rem;
    font-family: Rubik, sans-serif;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  .value {
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
    font-size: 1rem;
    color: $primary_700;
  }

  .value_unknown {
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
    font-size: 1rem;
    color: $black_low_emphasis;
  }

  .original_background {
    border-radius: 10px;
    padding: 1rem;

    &.yellow_background {
      background-color: $yellow-background;
    }

    &.blue_background {
      background-color: $primary_50;
    }
  }
}
