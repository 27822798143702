@import "@assets/styles/variables";

.invoice_description {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 200px;

  p {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p:first-of-type {
    margin-bottom: 0.2rem;
    font-weight: 400;
    font-size: 14px;
    color: $primary_200;
    cursor: pointer;
  }

  p:last-of-type {
    font-weight: 400;
    font-size: 12px;
    color: $black_medium_emphasis;
  }
}

.payment_description {
  margin: 0;
  color: $green;
  font-size: 14px;
  font-weight: 400;
}

.failed_payment {
  color: $red !important;
}

.status_container {
  border: 1px solid $outline;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 1rem;
  background-color: $white;

  p {
    margin: 0;
  }
}

.closed_status {
  align-items: center;
  justify-content: center;
  display: flex;

  div {
    border-radius: 20px;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem;
    background-color: $orange-background;

    p {
      margin: 0;
    }
  }
}

.pending_status {
  align-items: center;
  justify-content: center;
  display: flex;

  div {
    border-radius: 20px;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem;
    background-color: $grey-medium;

    p {
      margin: 0;
    }
  }
}

.paid_status {
  align-items: center;
  justify-content: center;
  display: flex;

  div {
    border-radius: 20px;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem;
    background-color: $green;
    color: $white;

    p {
      margin: 0;
    }
  }
}

.payment {
  margin: 0;
  color: $green;
}
