@import "@assets/styles/variables";

.payment_description {
  margin: 0;
  color: $green;
  font-size: 14px;
  font-weight: 400;
}

.failed_payment {
  color: $red !important;
}

.payment {
  margin: 0;
  color: $green;
}
