.empty_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  color: #011c3061;
}

.empty_state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110%;
  height: 80%;
  top: 0;
  position: absolute;
}
