@import "src/assets/styles";

.fixed_header {
  position: relative;
  margin-left: 0.5rem;
  top: 0;
  width: 100%;
}

.styled_container {
  margin: 1rem auto;
  height: 100%;
  width: 1000px;
}

.secondary_sidebar {
  padding-right: 1.5rem;
  margin-right: 0;
  border-left: 1px solid $outline;
  width: 275px;
}

.table_title {
  color: $primary_700;
  margin: 0;
}

.title_container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 1.5rem 0;
}
