@import "@assets/styles/variables";

.form_modal_container {
  max-width: 600px;
  min-height: 10rem;
}

.changed_field {
  background-color: $yellow-background !important;
}
