@import "@assets/styles/variables";

.top_bar {
  box-sizing: border-box;
  padding: 0.5rem 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey_medium;
  background-color: $white;
}

.renewal_flow_info_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .renewal_flow_title_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 30%;
  }

  .renewal_flow_info_content {
    width: 30%;
    margin-top: 1rem;
  }

  @media (max-width: 1300px) and (min-width: 1000px) {
    .renewal_flow_title_info {
      width: 40%;
    }

    .renewal_flow_info_content {
      width: 40%;
    }
  }

  @media (max-width: 1000px) and (min-width: 800px) {
    .renewal_flow_title_info {
      width: 60%;
    }

    .renewal_flow_info_content {
      width: 60%;
    }
  }

  @media (max-width: 800px) {
    .renewal_flow_title_info {
      width: 90%;
    }

    .renewal_flow_info_content {
      width: 90%;
    }
  }
}
