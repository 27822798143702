.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto !important;
  display: flex;
  flex-direction: column;
  width: 344px;

  .back_button {
    top: 4rem;
    left: 4rem;
    position: fixed;
    z-index: 10;

    @media (max-width: 750px) {
      margin-top: 3rem !important;
      position: inherit !important;
    }
  }

  .inner_container {
    margin-top: 10rem;
    margin-bottom: 1rem;

    @media (max-width: 750px) {
      margin-top: 3rem;
    }
  }
}
