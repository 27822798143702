@import "@assets/styles/variables";

.item {
  flex: 0 0 auto;
  align-self: flex-start;
  position: relative;
  box-sizing: border-box;
  padding: 0.3rem 0.3rem 1rem 1.2rem;
  width: 335px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $grey_light;
  border: 1px solid $outline;
  box-shadow: 0 2px 10px 0 rgba(232, 232, 232, 1);

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .type {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        margin: 0 0 0 0.2rem;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .info {
    margin: 0 0 0.7rem 0;

    &:last-of-type {
      margin: 0;
    }

    &.card-name {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .expiry-line {
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      &.expired {
        color: $red;
      }
    }

    .expired-container {
      margin-right: 1.3rem;
      border-radius: 25px;
      background-color: $red-background;
      padding: 4px 6px 4px 6px;

      p {
        margin: 0;
        color: $red;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .default-container {
    position: absolute;
    right: 1.3rem;
    bottom: 0.8rem;
    border-radius: 25px;
    background-color: $green_background;
    padding: 4px 6px 4px 6px;

    p {
      margin: 0;
      color: $green;
      font-weight: 400;
      font-size: 12px;
    }
  }
}
