@import "../variables";

// Needs to be applied to the div that includes a p element and shows underline for the p element
@mixin underline-animation($color: $primary_500) {
  p {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: $color;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  &:hover {
    p:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}
