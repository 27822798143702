@import "src/assets/styles/index";

.hamburger_menu {
  background-color: white;
  border-radius: 25px;
  border: 1px solid $outline;
  padding: 0.4rem 0.5rem;
  display: flex;
  align-items: center;

  .hamburger_menu_avatar {
    margin-right: 1rem;
    background-color: $black_high_emphasis;
  }

  .hamburger_menu_icon {
    margin-right: 0.5rem;
  }

  .hamburger_menu_avatar_text {
    color: #ffffff;
  }
}

.hamburger_menu_list_item_icon {
  margin-right: 0.5rem;
}
