@import "variables";
@import "mixins";

// General Styling
html {
  body {
    margin: 0;
    background-color: $white;
    font-family:
      "Rubik",
      "Kollektif",
      "Roboto",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .MuiTooltip-tooltip {
    background-color: $black;
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: auto;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .padding_left {
    padding-left: 1rem;
  }

  .margin_right {
    margin-right: 1rem;
  }

  .flex_row_space_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex_end_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  //Typography
  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 28px;
    color: $primary_700;
    margin: 0;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: $primary_700;
    margin: 0;
  }

  .body {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $primary_700;
  }

  .body_regular {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: $primary_700;
  }

  .high_emphasis {
    color: $black_high_emphasis;
  }

  .bold {
    font-weight: 500;
  }

  .body2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #687683;
    padding: 0;
    margin: 0;
  }

  .body_regular_2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: $black_high_emphasis;
    margin: 0;
  }

  .sub_label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin: 0.4rem 0;
    color: $black_medium_emphasis;
  }

  .item_label {
    color: $black_medium_emphasis;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }

  .item_value_bold {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  .overline {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.07em;
    color: rgba(1, 28, 48, 0.6);
    text-transform: uppercase;
  }

  .overline_bold {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.07em;
    color: #23394a;
    text-transform: uppercase;
  }

  .overline_light {
    color: $black_medium_emphasis;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.07em;
    line-height: 14px;
    text-align: left;
    text-transform: uppercase;
    margin: 0.5rem 0;
  }

  .currency_text_large {
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 45px;
    color: $primary_700;
    padding: 0;
    margin: 0;
  }

  .changed-field-yellow-background {
    background-color: $yellow-background !important;
  }

  .green {
    color: $green;
  }

  .menu-item {
    padding: 0.8rem 1.2rem;
  }

  a {
    color: $primary_200;
    text-decoration: none;

    &:visited {
      color: $primary_200;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

// Popovers
html {
  .module-section-popover {
    .popover_option {
      @include transition();
      cursor: pointer;
      padding: 0.8rem;

      p {
        margin: 0 0 0 0.2rem;
      }

      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        background-color: $primary_50;
      }
    }
  }
}

// Components
html {
  .sample-card {
    @include transition();
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
    border: 1px solid $black;
    border-radius: 10px;
    background-color: $blue;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $white;
      color: $black;
    }

    .header {
      font-weight: 500;
    }

    .body {
      font-weight: 400;
    }
  }

  .styled-list {
    $background: var(--background);
    max-width: 475px !important;
    padding: 5px !important;
    background: $background;
    min-height: 30rem;
    border-radius: 10px;
  }

  .admin-avatar {
    $hoverColor: var(--hover-color);

    &:hover {
      cursor: pointer;
    }

    &.has-hover-color {
      &:hover {
        background-color: $hoverColor !important;
      }
    }
  }

  .search-drop-down-wrapper {
    width: 500px;
    margin-bottom: 1rem;
    overflow: hidden !important;
    color: $grey_dark;
  }

  .admin-content-container {
    box-sizing: border-box;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;

    .page_title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        margin: 0;
        font-size: 2.1rem;
        font-weight: 500;
      }
    }

    .tab_bar {
      width: 100%;
      margin-top: 2.5rem;
    }
  }

  .admin-detail {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .admin-detail-main {
    position: sticky;
    overflow-y: scroll;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .admin-detail-sidebar {
    padding: 1rem;
    box-sizing: border-box;
    display: block;
    position: sticky;
    overflow-y: scroll;
    top: 0;
    height: 100% !important;
    border-left: 1px solid $outline;
    width: 295px;
    flex-shrink: 0;

    :global .MuiDivider-root {
      margin-block: 0.3rem;
    }

    .right_sidebar_content {
      padding: 1rem;
    }

    .approval_section {
      padding-inline: 0.8rem;
    }
  }

  .secondary-sidebar {
    box-sizing: border-box;
    padding-right: 1.5rem;
    margin-right: 0;
    margin-left: 1rem;
    border-left: 1px solid $outline;
    width: 290px;
    min-height: calc(100vh - $header-height);
    flex-shrink: 0;
  }

  .data-table-status-container {
    display: inline-block;
    border: 1px solid $outline;
    border-radius: 100px;
    padding: 0.3rem 0.7rem;

    .dot {
      $color: var(--background-color);
      $borderColor: var(--border-color);
      height: 15px;
      width: 15px;
      background-color: $color;
      border-radius: 50%;
      display: inline-block;
      border: 2px solid $borderColor;
      margin-right: 10px;
    }
  }

  .discount-alert-container {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 10px;
    background-color: $green_background;
    padding: 1rem;

    p {
      font-weight: 400;
      font-size: 14px;
      margin: 0.2rem 0 0;
    }

    svg {
      margin-right: 0.4rem;
      color: $green;
    }

    &.has-margin-bottom {
      margin-bottom: 0.5rem;
    }
  }

  .divider {
    border-top: 1px solid $outline;
    margin: 1rem 0;
  }

  .divider_bold {
    border-top: 2px solid $primary_700;
    margin: 1rem 0;
  }

  .outlined_container {
    border-radius: 10px;
    border: 1px solid $outline;
    padding: 1.5rem 1rem;
  }

  .text_button {
    cursor: pointer;
    color: $primary_200;
    text-decoration: underline;
    text-decoration-color: $primary_200;
  }

  .MuiDataGrid-columnsPanelRow {
    .MuiFormControlLabel-root {
      height: 50px;
      background-color: unset;
      padding-right: 1rem;
    }
  }
}

// Screens
html {
  .sample-screen {
    padding-block: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sample-card {
      margin-bottom: 1rem;
    }
  }

  .dashboard-screen {
    display: flex;
    margin-top: -1rem;

    .dashboard-screen-content {
      margin-inline: auto;
      margin-top: 3rem;
      max-width: 1000px;
    }
  }

  .users-screen {
    display: flex;

    .users-screen-content {
      margin: 1rem auto;
      height: 100%;
      width: 1000px;
    }
  }
}
