@import "@assets/styles/variables";

.top_sub_title {
  font-size: 14px;
  font-weight: 400;
  color: $black_medium_emphasis;
  margin: 0;
}

.sub_title {
  color: $black_medium_emphasis;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.text_button {
  color: $primary_200;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: fit-content;

  &:hover {
    p {
      text-decoration-color: $primary_200;
      text-decoration: underline;
    }
  }
}

.main_value {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.details_container {
  border-radius: 10px;
  border: 1px solid $outline;
  padding: 1rem 1.5rem;

  .flex_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
}

.table_header {
  display: flex;
  margin-bottom: 0.7rem;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.7px;
  }
}

.table_content {
  display: flex;
}

.table_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0.2rem;
  }
}
