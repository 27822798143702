@import "src/assets/styles/variables";

.price_tag {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: $primary_50;
  margin-left: auto;
  margin-right: -2rem;
  padding: 0.5rem 2rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
