.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto !important;
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 100vh;
  padding-top: 3rem;

  .back_button {
    left: 4rem;
    top: 4rem;
    position: fixed;
    z-index: 10;

    @media (max-width: 750px) {
      margin-top: 3rem !important;
      margin-left: 1rem;
      position: inherit !important;
      margin-bottom: 3rem;
    }
  }

  .three_dots_spacer_wrapper {
    padding-inline: 0.5rem;

    svg {
      height: 3.5px;
    }
  }
}
