.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .button_wrapper {
    display: flex;
    width: 100%;
  }

  .page_container_back_button {
    position: fixed;
    left: 4rem;
    top: 4rem;
    z-index: 10;

    @media (max-width: 750px) {
      margin-top: 3rem !important;
      margin-left: 1rem;
      position: inherit !important;
    }
  }

  .inner_container {
    max-width: 350px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;

    .logo {
      max-width: 350px;
      margin: 5rem auto;

      @media (max-width: 750px) {
        width: 300px;
        height: 150px;
        margin-top: 1rem;
      }
    }
  }

  .name_fields {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}
