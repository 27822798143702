@import "src/assets/styles/index";

.statement_balance_card_container {
  margin-top: 0;
  padding: 0.5rem 1.5rem 1rem;
  background: $white_high_emphasis;
  border: 1px solid $outline;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  @media (min-width: 720px) {
    margin-top: 4.8rem;
  }
}

.what_is_payment_icon {
  color: $blue;
  margin-right: 0.5rem;
}

.balance_card_links {
  color: $primary_200;

  :hover {
    cursor: pointer;
    color: $primary_400;
  }
}

.more_about_balance_modal_body {
  max-width: 700px;
  padding: 0.5rem 1.5rem;
}
