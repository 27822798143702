//TODO: implement the theme color variables
$white: white;
$white_high_emphasis: rgba(255, 255, 255, 0.95);

$black: black;
$blue: #0c55b5;
$red: #f00f3c;
$red-background: #fef3f5;
$orange-background: #fce8d0;
$yellow-background: #fef4c0;

$green: #4aa01f;
$green_background: #e6fae0;

//Primary
$primary_50: #f2f6fb;
$primary_200: #0c55b5;
$primary_400: #053c70;
$primary_500: #01304e;
$primary_700: #011c30;

$grey_dark: #5a5a5a;
$grey_medium: #e8e8e8;
$grey_light: #fafafa;

$outline: #ccd6dc;
$blueBg: #e5edf7;

$black_medium_emphasis: #011c3099;
$black_low_emphasis: #011c3061;
$black_high_emphasis: rgba(1, 28, 48, 0.87);

$grey-medium: #e8e8e8;
$background-grey: #f5f5f5;
