@import "@assets/styles/variables";
@import "@assets/styles/mixins";

.container {
  border: 1px solid $outline;
  border-radius: 10px;
  position: relative;
  background-color: $white;

  .header {
    border-radius: 10px 10px 0 0;
    background-color: $primary_400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.2rem;

    .title_section {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: $white;
      }

      .title {
        margin: 0 0 0 0.5rem;
        color: $white;
        font-weight: 500;
        font-size: 20px;

        @media (max-width: 450px) {
          font-size: 17px;
        }
      }
    }

    .drop_down {
      cursor: pointer;
      z-index: 2;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      border: 1px solid $outline;
      padding: 0.5rem 0.7rem;

      svg {
        @include transition();
        transform: rotate(0deg);
      }

      p {
        margin: 0 0.2rem 0 0;
      }

      &.open {
        border: 1px solid $primary_200;

        svg {
          transform: rotate(-180deg);
        }
      }

      @media (max-width: 450px) {
        width: 145px;
      }
    }

    @media (max-width: 338px) {
      background-color: $white;
    }
  }

  .content {
    .place_holder {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      margin: 4rem 1rem;
      color: $black_medium_emphasis;
    }
  }

  .background_image {
    overflow: hidden;
    width: 100%;
    z-index: 1;
    height: 74px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 9px 9px 0 0;

    img {
      width: 100%;
      object-fit: fill;
      opacity: 0.7;
      height: 100%;
    }
  }

  .show_more_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    :hover {
      text-decoration: underline;
    }

    div:last-of-type {
      cursor: pointer;
      margin-right: 0.2rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: $primary-200;
        margin: 0;
      }

      svg {
        @include transition();
        transform: rotate(0deg);
        color: $primary-200;
      }

      &.open {
        svg {
          @include transition();
          transform: rotate(-180deg);
        }
      }
    }
  }
}
