@import "@assets/styles/variables";

.container {
  display: flex;
  min-height: 100%;

  .address_column_container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 160px;

    p {
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    p:first-of-type {
      margin-bottom: 0.2rem;
      font-weight: 400;
      font-size: 16px;
    }

    p:last-of-type {
      font-weight: 400;
      font-size: 14px;
      color: $black_medium_emphasis;
    }
  }

  .status_container {
    border: 1px solid $outline;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem;
    background-color: $white;

    p {
      margin: 0;
    }
  }
}

.fixed_header {
  position: relative;
  margin-left: 0.5rem;
  top: 0;
  width: 100%;
}

.styled_container {
  margin: 1rem auto;
  height: 100%;
  width: 1000px;
}

.table_title {
  color: $primary_700;
  margin: 0;
}

.title_container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 1.5rem 0;
}

.avatar_container {
  display: flex;
  align-items: center;
}
