.main_title {
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}
