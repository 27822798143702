@import "@assets/styles/variables";

.loading_component_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    width: 7rem;
  }
}
