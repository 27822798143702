@import "src/assets/styles/variables";

.h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 28px;
  color: $primary_700;
  margin: 0;
}

.h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: $primary_700;
  margin: 0;
}

.h3 {
  color: $primary_700;
  font-size: 0.875rem;
  font-family: Rubik, sans-serif;
  font-weight: 600;
  letter-spacing: 0.04375rem;
}

.body {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $primary_700;
}

.bold {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: $primary_700;
}

.bolder {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: $primary_700;
}

.high_emphasis {
  color: $black_high_emphasis;
}

.body2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #687683;
  padding: 0;
  margin: 0;
}

.small_font {
  font-size: 12px;
}

.overline {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.07em;
  color: rgba(1, 28, 48, 0.6);
  text-transform: uppercase;
}

.overline_bold {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.07em;
  color: #23394a;
  text-transform: uppercase;
}

.currency_text_large {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  color: $primary_700;
  padding: 0;
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}

.sub_heading {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}
