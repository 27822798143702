@import "@assets/styles/variables";
@import "@assets/styles/mixins";

.screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top_bar {
  box-sizing: border-box;
  padding: 0.5rem 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey_medium;
  background-color: $white;
}

.title {
  width: 100%;

  p {
    font-weight: 500;
    font-size: 2rem;
    margin-left: 1.2rem;
  }
}

.content-container {
  box-sizing: border-box;
  padding: 1.2rem;
  width: 100%;
  background-color: $primary_50;
}

.place-holder-container {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
    flex-direction: column;
    .place-holder-content {
      order: 1;
      margin-block: 1.5rem;
    }

    .place-holder-image {
      max-width: 100%;
    }
  }
}

.place-holder-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.place-holder-text {
  margin: 0 0 0.5rem 0.2rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.add-payment-method-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .text-button {
    margin: 0;
    color: $primary_200;
  }

  svg {
    color: $primary_200;
  }

  &:hover {
    .text-button {
      text-decoration: underline;
      text-decoration-color: $primary_200;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .text-button {
      color: $black_low_emphasis;
      text-decoration: unset;
    }

    svg {
      color: $black_low_emphasis;
    }
  }
}

.items-container {
  display: flex;
  flex-wrap: wrap;

  .item {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  @media (max-width: 820px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .item {
      margin-right: 0;
    }
  }
}

.indicator {
  margin: 0 0 0 0.5rem;
  font-size: 14px;
  color: $black_low_emphasis;
}
