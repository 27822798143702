@import "@assets/styles/variables/index";

.missing_parent {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100%;
  width: 100%;

  .missing_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
  }

  .missing {
    margin-top: 10%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0 0 0.2rem;
      color: black;
      font-weight: 300;
      font-size: 0.8rem;
    }

    .title {
      font-size: 1.3rem;
      font-weight: 700;
    }
  }
}

.top_bar {
  box-sizing: border-box;
  padding: 0.5rem 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey_medium;
  background-color: $white;
}

.title {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.sub_title {
  margin-top: 0;
}
