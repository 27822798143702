@import "src/assets/styles/variables";

.container {
  border-radius: 10px;
  border: 1px solid $outline;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}

.closed_background {
  background-color: $grey_light;
}
