@import "src/assets/styles/variables";
@import "@assets/styles/mixins";

.container {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .info_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }

  .form_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .form_title {
      margin-left: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .app_version_section {
      background-color: $blueBg;
      width: 100%;
      position: absolute;
      bottom: 0;
      text-align: center;

      @media (max-width: 450px) {
        position: unset !important;
      }
    }
  }

  .info {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0 0 0.2rem;
      color: $white;
      font-weight: 500;
      font-size: 2rem;
    }

    .description {
      margin-top: 0.3rem;
      line-height: 22.4px;
      font-size: 1rem;
      font-weight: 400;
    }

    .svg {
      margin-bottom: 1rem;
      margin-left: -15px;
      height: 100px;

      path {
        fill: white;
      }
    }
  }

  .info_container_mobile {
    display: none;
    padding: 1.5rem 1rem 1.5rem 2rem;

    .info {
      width: 100%;
    }

    .show_more_container {
      margin-top: 1rem;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      p {
        color: $white;
        font-size: 1rem;
        font-weight: 400;
        margin: 0 0.5rem 0 0;
      }

      svg {
        @include transition();
        transform: rotate(0deg);
        color: $white;
        height: 1rem;
        width: 1rem;
        margin-bottom: 0;
      }

      &.open {
        svg {
          @include transition();
          transform: rotate(-180deg);
        }
      }
    }
  }

  .buttons_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .forgot_button_wrapper {
    margin-top: 0.5rem;
    margin-bottom: 20px;
    display: flex;
  }

  .small_link_button {
    color: $primary_200 !important;
    margin-top: 10px !important;

    &:hover {
      color: $primary_400 !important;
      cursor: pointer;
    }
  }

  @media (max-width: 520px) {
    flex-direction: column;

    .form_container {
      width: 100%;
      margin-top: 2rem;
    }
    .info_container {
      display: none;
    }
    .info_container_mobile {
      display: unset;
    }

    .buttons_container {
      margin-bottom: 2rem;
    }

    .info {
      p {
        font-size: 1.5rem;
      }
    }
  }
}
