@import "@assets/styles/variables";
@import "@assets/styles/mixins";

.invoice_editor {
  $height: var(--height);
  $width: var(--width);
  height: $height;
  width: $width;
  background-color: $grey_light;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;

  .cancel_text_button {
    color: $primary_200;
    cursor: pointer;
    margin-left: 1rem;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $primary_200;
    }
  }

  p {
    margin: unset;
  }

  .top_bar {
    position: absolute;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $white;

    .top_bar_content_wrapper {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $grey_medium;

      .top_bar_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 60%;

        .top_bar_info {
          margin-right: 1rem;

          p:first-of-type {
            font-size: 1.5rem;
          }

          p:last-of-type {
            color: $black_medium_emphasis;
            font-size: 0.875rem;
          }
        }
      }
    }

    .history_section_label_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid $grey_medium;

      .history_section_label {
        width: 60.5%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-block: 0.5rem;

        > div {
          display: flex;
          cursor: pointer;
        }

        svg {
          @include transition();
          transform: rotate(0deg);
        }

        &.open {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }

    .history_section_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .history_section {
        display: flex;
        padding-block: 2rem;
        flex-direction: column;
        max-height: 320px;
        overflow-y: scroll;

        .history_item {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin-bottom: 2rem;

          .history_details {
            font-size: 14px;
            font-weight: 400;

            .memo {
              font-style: italic;
              margin-left: 0.2rem;
            }
          }

          .history_date {
            font-size: 10px;
            font-weight: 400;
            color: $black_medium_emphasis;
          }
        }
      }

      &.open {
        border-bottom: 1px solid $grey_medium;
        box-shadow: 0 8px 12px 0 rgba(1, 48, 78, 0.1);
      }
    }
  }

  .main_section {
    background-color: $white;
    margin-top: 5rem;
    margin-bottom: 6rem;
    padding-inline: 5rem;
    width: 55%;
    box-shadow:
      0 5px 10px 0 rgba(1, 48, 78, 0.1),
      0 3px 18px 0 rgba(1, 48, 78, 0.08),
      0 8px 12px 0 rgba(1, 48, 78, 0.1);

    .page_title {
      margin-top: 10rem;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .second_block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .date_lines {
        align-items: center;
        justify-content: space-between;
        display: flex;
        margin-bottom: 0.8rem;
      }
    }

    .table_container {
      width: 100%;
      display: flex;
      flex-direction: column;

      .table_header {
        width: 100%;
        display: flex;
        border-bottom: 1px solid $black;
        padding-bottom: 0.4rem;
      }

      .table_description {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .table_unit {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .table_amount {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .table_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-block: 1.2rem;

        .table_items {
          width: 100%;
          display: flex;
        }
      }

      .table_footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $black;
        padding-top: 0.4rem;
      }
    }
  }
}
