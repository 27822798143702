@import "src/assets/styles";

$containerPadding: 1rem 1.5rem;

//TODO: refactor below class into the detail component's own scss
.details_container {
  border-radius: 10px;
  border: 1px solid $outline;
  padding: $containerPadding;

  .flex_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
}

.right_column {
  @media (min-width: 720px) {
    position: sticky;
    height: 100%;
    top: 0;
  }
}

.bread_crumbs {
  padding: 0.5rem 0 0.5rem 1.2rem;
}

.empty_text {
  color: $black_medium_emphasis;
}

.flex_column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog_body_text {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #011b2f;
}
