@import "src/assets/styles/index";

.stepper {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.step_container {
  padding: 0.5rem 0;
  width: fit-content;

  .step_container_icon {
    padding-right: 1rem;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.step_container_link {
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  color: $primary_200;
}
