@import "@assets/styles/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  border-radius: 10px;
  background-color: $green_background;
  padding: 0.9rem;

  p {
    margin: 0;
  }

  .status_title {
    color: $green;
    font-size: 1rem;
    font-weight: 600;
  }

  .linked_info {
    color: $black_medium_emphasis;
    font-size: 0.8rem;
  }

  svg {
    color: $green;
    margin-right: 0.6rem;
  }

  &.changes {
    background-color: $primary_50;

    .status_title {
      color: $primary_200;
    }

    svg {
      color: $primary_200;
    }
  }
}

.loading {
  margin: 1rem;
  height: 70px !important;
}
